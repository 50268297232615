<template>
    <mct-lista-padrao ref="childComponent" :source="source" :titulo="titulo" :showModal="showModal" @setData="setData">
        <mct-grid-lista id="pes_codigo" :columns="colunas" :data="data" :query="query" :source="source" @toggleOrder="toggleOrder" :showModal="showModal" @setDescricao="setDescricao">
            <template #pes_nome="{ column, row}">
                <div v-if="column.nome === 'pes_nome' && row['vinculados'].length > 0">
                    <a href="#" class="text-muted" v-for="(columnSec, keySec) in row['vinculados']" :key="keySec" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${columnSec.pes_codigo}/visualizar`)">[ {{columnSec['pes_nome']}} ]</a>
                </div>
                <div v-if="column.nome === 'pes_nome' && row['pes_ult_presenca']">
                    <a href="#" class="text-muted">[ Últ. Presença: {{row['pes_ult_presenca'] | data}} ]</a>
                </div>
            </template>
        </mct-grid-lista>
    </mct-lista-padrao>      
</template>

<script>

import Vue from 'vue'
import MctListaPadrao from '@/components/comum/MctDataViewer.vue'
import MctGridLista from '@/components/comum/MctGridLista.vue'

export default {
    name: 'PessoaLista',
    components: {
        MctListaPadrao,
        MctGridLista
    },
    props: ['source', 'titulo', 'showModal'],
    data (){
        return {
            data: {},
            columns: {},
            query: {},
            colunas: [
                {nome: 'pes_codigo', titulo: 'CÓDIGO', classe: '1', tamanhoCol: '70px', filtro: true},
                {nome: 'pes_situacao', titulo: 'SIT.', tipo:'checkbox', classe: '1', tamanhoCol: '40px'},
                {nome: 'pes_tipo', titulo: 'TIPO', classe: '1', tamanhoCol: '50px', filtro: true},
                {nome: 'pes_tipo_cad', titulo: 'TIPO CAD.', classe: '1', tamanhoCol: '50px', filtro: true},
                {nome: 'pes_nome', titulo: 'NOME / RAZÃO SOCIAL', classe: '4', tamanhoCol: '300px', filtro: true},
                {nome: 'pes_fantasia', titulo: 'FANTASIA', classe: '', tamanhoCol: '250px', filtro: true},
                {nome: 'pes_cpf', titulo: 'CPF / CNPJ', classe: '', tamanhoCol: '120px', filtro: true},
                {nome: 'logra_descricao', titulo: 'LOGRADOURO', classe: '', tamanhoCol: '220px', subtab: 'logradouro'},
                {nome: 'logra_bairro', titulo: 'BAIRRO', classe: '', tamanhoCol: '200px', subtab: 'logradouro'},
                {nome: 'logra_cidade', titulo: 'CIDADE', classe: '', tamanhoCol: '180px', subtab: 'logradouro'},
                {nome: 'logra_cep', titulo: 'CEP', classe: '', tamanhoCol: '80px', subtab: 'logradouro'},
                {nome: 'logra_estado', titulo: 'ESTADO', classe: '', tamanhoCol: '60px', subtab: 'logradouro'},
            ]
        }
    },
    methods: {
        toggleOrder(column) {
            this.$refs.childComponent.toggleOrder(column);
        },
        setDescricao (ev, data ){
            ev.preventDefault()
            this.$emit('setDescricao', data)
        },
        setData (data, columns, query){
            this.data = data
            this.columns = columns
            this.query = query
        },
        redirect (ev, link) {
            ev.preventDefault();
            
            this.$router.push(link)
        },        
        getIndexes (row) {
            var indexes = ''
            if (typeof this.id === 'object'){
               for (var index in this.id) {               
                    if (indexes === ''){
                        indexes = row[this.id[0]]
                    } else {    
                        indexes = indexes + '/' + row[this.id[index]]
                    }
               }
            } else {
                indexes = row[this.id]   
            }
            return indexes
        },
        onFilter (campo){
            //console.log(campo) 
            this.$emit('getData')               
        }

    }
}
</script>